@import "styles/mixins.scss";

.welcome-layout {
  @include welcome-layout("../images/welcome-bg.png");

  &.--welcome {
    .welcome-page {
      @include flex-box(column, center, center);

      gap: 75px;
      height: 100%;

      .welcome-header {
        text-align: center;

        .header-title {
          margin-bottom: 17.53px;
        }
        .header-description {
          color: $text_color_secondary;
          font-size: $text_size_3;
        }
      }

      .cards-container {
        @include grid(repeat(2, 376px), auto, center, center, 75px);

        .card {
          @include flex-box(column, center, center);

          width: 376px;
          height: 266px;
          text-align: center;
          border-radius: 8px;
          background-color: $white_color;
          box-shadow: 0px 1px 7px rgba($black_color, 0.12);

          &:focus {
            border: 2px solid $link_color;
          }

          .key-image {
            margin-bottom: 19px;
          }

          .hands-image {
            margin-bottom: 12px;
          }

          .heading {
            font-weight: 600;
            font-size: 24px;
            color: $primary_color;
          }

          .paragraph {
            font-size: $text_size_5;;
            color: $text_color_secondary;
            margin: auto;
            width: 307px;
          }
        }
      }
    }
  }
}
