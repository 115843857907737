@import "styles/theme.scss";
@import "styles/mixins.scss";

.tile-wrapper {
  width: 201px;
  height: 80px;
  background: $white_color;
  box-shadow: 0px 2px 6px rgba(165, 165, 165, 0.25);
  border-radius: 4px;
  padding: 16px;
  display: grid;

  .tile-header {
    @include grid(auto 1fr auto, auto, space-between, center, 8px);

    .value {
      font-weight: 500;
      font-size: 26px;
      line-height: 24px;
      color: $black_color;
    }

    .till-date {
      font-size: 10px;
      color: $text_color_light;
    }

    .unit {
      font-size: $text_size_3;;
      color: $black_color;
    }

    .--green {
      color: $success_color;
    }

    .--orange {
      color: $secondary_color;
    }

    .--niagara {
      color: #0abe93;
    }

    .--yellow {
      color: $yellow_color;
    }

    .--blue {
      color: $blue_color;
    }

    .--purple {
      color: $primary_color;
    }

    .link {
      @extend .--orange;
    }
  }

  .tile-title {
    font-size: $text_size_4;
    line-height: 24px;
    color: $text_color_secondary;
    margin-top: 4px;
  }
}

.--sea-green {
  color: #2c9f3f;
}
