@import "styles/theme.scss";

.checkbox {
  &.--filter-header {
    color: $black_color;

    .ant-checkbox ~ span {
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 4px;
      }
    }
  }

  &.--filter-light {
    color: $text_color_light;
    .filter-text {
      font-size: $text_size_6;
    }
  }
}

.ant-checkbox-wrapper {
  &.checkbox:not(.--filter-header) {
    display: flex;
    align-items: center;

    .ant-checkbox ~ span {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 0;

      .issue-checkbox-label {
        // @include max-width-ellipsis(12ch);

        display: block;
      }

      .ant-tag {
        &.common-tag {
          background: rgba($text_color_secondary, 0.06);
          color: $black_color;
          font-size: $text_size_6;
        }
      }
    }
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      height: 1px;
    }
  }
}
