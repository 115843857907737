@import "styles/theme.scss";
@import "styles/mixins.scss";

@include login-page();

.login-page {
  .flag {
    transform: scale(1.5);
    left: 25%;
  }
}
.react-tel-input {
  .login-phone-input {
    font-size: $text_size_3;;
    width: 425px;
    height: 46px;
    padding-left: 80px;
    color: $text_color_secondary;

    &.--user {
      width: 225px;
      height: 36px;
      padding-left: 45px;
    }

    &::placeholder {
      letter-spacing: 0.05em;
      color: $black_color;
      opacity: 0.3;
    }
  }

  .flag-dropdown {
    &.user-flag {
      border: none;
      background-color: transparent;

      &:hover {
        background-color: inherit;
      }

      .selected-flag {
        background-color: inherit;
        width: 30px;

        &:hover {
          border-color: $primary-color;
          background-color: inherit;
        }

        &:focus {
          background-color: inherit;
          border-color: $primary-color;
        }
      }

      &.--summary {
        .selected-flag {
          margin-top: 1px;
        }
      }
    }

    .selected-flag {
      width: 70px;

      .arrow {
        border-style: solid;
        border-width: 2px 2px 0 0;
        height: 0.45em;
        top: 3px;
        width: 0.45em;
        border-color: $light_gray_color;
        margin-left: 5px;
        transform: rotate(135deg);
      }
    }
  }

  .invalid-number-message {
    z-index: 0;
    inset: unset;
    bottom: -45%;
    background: transparent;
  }
}

.back-circle {
  position: absolute;
  top: 42px;
  left: 50px;
  z-index: 1;
}
