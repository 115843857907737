@import "styles/theme.scss";

.ant-radio-group {
  &.filter-radio-group {
    display: grid;
    grid-template-columns: 1fr;

    .ant-radio-wrapper {
      font-size: $text_size_5;
      color: $text_color_secondary;

      .ant-radio-checked {
        &::after {
          border: 1px solid $secondary_color;
        }
        .ant-radio-inner {
          border-color: $secondary_color;

          &::after {
            background-color: $secondary_color;
            margin-top: 0.3px;
          }
        }
      }
    }
  }
}
