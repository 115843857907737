@import "styles/theme.scss";
@import "styles/mixins.scss";

.issues-content,
.zones-content,
.clients-content,
.financials-content,
.vehicles-content {
  .common-table {
    .ant-table {
      height: calc(100vh - 160px);
      overflow: auto;
    }
  }
}

.users-content {
  .common-table {
    .ant-table {
      height: calc(100vh - 190px);
      overflow: auto;
    }
  }
}

.common-table {
  .ant-table-cell {
    font-size: $text_size_5;
    color: $text_color_secondary;

    .assignees-container {
      display: flex;
      gap: 5px;
    }

    .count-box {
      display: inline-grid;
      width: min-content;
      height: 20px;
      background-color: $primary_color;
      color: $white_color;
      padding: 2px 4px;
      border-radius: 4px;
      cursor: default;
    }

    .user-icon-container {
      margin-bottom: 0;

      .user-image {
        width: 100%;
        border-radius: 50%;
      }
    }

    .user-icon-container-table {
      @include flex-box(row, flex-start, center);

      gap: 14px;
      margin-bottom: 0;

      .user-name {
        @include max-width-ellipsis(20ch);
      }
    }

    .user-detail {
      @include flex-box(column, center, flex-start);

      margin-bottom: 0;
      gap: 5px;

      .user-detail-wrapper {
        @include flex-box(row, center, flex-start);

        @include gap(31px);

        .list-text-container {
          @include flex-box(row, center, center);

          margin-bottom: 0;

          @include gap(8px);

          .icon {
            color: $light_gray_color;
          }

          figcaption {
            @include flex-box(row, center, center);

            gap: 6px;
          }
        }
      }

      svg {
        margin: 0 1px 0 1px;
      }
    }

    .dot-separator {
      &:first-child {
        position: relative;
        margin-right: 16px;

        &::after {
          content: "";
          position: absolute;
          top: 6px;
          border-radius: 50%;
          width: 3px;
          height: 3px;
          left: calc(100% + 8px);
          background: $gray_color;
        }
      }
    }

    .capitalize {
      display: inline-block;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .ant-table-row {
    &:hover {
      box-shadow: 0px 2px 5px rgba($black_color, 0.14);

      .ant-table-cell {
        &.open-link {
          .show-on-hover {
            visibility: visible;
          }
          .hide-on-hover {
            visibility: hidden;
          }
        }

        &.edit-vehicle > .update-vehicle {
          visibility: visible;

          & > * {
            cursor: pointer;
          }
        }
      }
    }

    .ant-table-cell {
      &.action-menu {
        width: auto;

        .ant-dropdown-trigger {
          display: inline-grid;
          place-items: center;
          color: $light_gray_color;
          padding: 5px 10px;
          border-radius: 50%;

          &.ant-dropdown-open {
            visibility: visible;
            background: rgba($secondary_color, 0.2);

            svg {
              color: $secondary_color;
            }
          }
        }
      }

      &.edit-vehicle > .update-vehicle {
        visibility: hidden;
        color: $light_gray_color;
      }

      &.open-link {
        // border: 1px solid red;
        // &.--user {
        //   @include flex-box(row, center, baseline);

        //   padding-top: 6px;
        // }

        // &.--issues {
        .table-action-container {
          @include gap(13px);
        }

        &.--user {
          .table-action-container {
            @include gap(1px);
          }
        }
        // }
        .icon {
          margin: 0 3px 0 0;
          padding-top: 3px;
        }

        .show-on-hover {
          visibility: hidden;
        }

        .hide-on-hover {
          visibility: visible;
        }
      }
    }
  }
}

.redstarIcon {
  color: $error_color;
}

.safety-rating {
  svg {
    margin-left: 4px;
  }
}
.yellowstarIcon {
  color: $yellow_color;
}

.overlay-user-details-card {
  min-width: 243px !important;
}

.user-details-card {
  @include grid(60px 1fr 11px, auto, center, flex-start, 10px);

  height: 114px;
  background: $white_color;
  box-shadow: 0px 2px 6px rgba($black_color, 0.25);
  border-radius: 6px;
  padding: 11px 12px;

  .user-image-wrapper {
    .user-image {
      height: 39px;
      width: 39px;
    }
  }

  .user-details {
    display: grid;
    font-size: $text_size_5;

    .user-name {
      @include max-width-ellipsis(20ch);

      font-size: 18px;
      color: $text_color_secondary;
      margin-bottom: 2px;
    }

    .user-contact-info {
      @include flex-box(row, flex-start, center);

      gap: 6px;
      color: $text_color_secondary;

      .--email {
        @include max-width-ellipsis(20ch);
      }

      svg {
        color: $light_gray_color;
      }
    }
    .user-details-cta {
      color: $link_color;
      margin-top: 5px;

      &:hover {
        color: $link_hover_color;
      }
    }
  }

  .user-edit-icon {
    color: $light_gray_color;
    cursor: pointer;
  }
}

.ant-dropdown-menu {
  .delete-action {
    color: $error_color;
  }
}

.image-rounded {
  border-radius: 50%;
}
