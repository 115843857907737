.vehicle-detail-content {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr auto;
  grid-template-rows: 565px 0.45fr;
  margin-right: 17px;

  .__detail-container {
    display: flex;
    border-bottom: 1px solid $secondary_border_color;
    margin-right: 4px;

    .back-icon {
      cursor: pointer;
      margin: 50px 40px 50px 15px;
    }

    .detail-content {
      flex: 1;

      .vehicle-route {
        margin: 12px 0;
        font-size: $text_size_6;

        .vehicles-link {
          font-size: inherit;
          padding: 0;
        }
      }

      .common-table {
        margin-bottom: 20px;

        td {
          .last-maintaned-cell {
            .ant-btn {
              height: 19px;
              padding-top: 0;
              padding-bottom: 0;
              font-size: inherit;

              > svg {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .__gallery-container {
    display: flex;
    flex-direction: column;
    width: 503px;
    border-bottom: 1px solid $secondary_border_color;

    .action-container {
      display: flex;
      margin-top: 15px;

      .action-edit,
      .action-archive {
        > svg {
          margin-right: 5px;
        }
      }

      .action-edit {
        margin-left: auto;
        margin-right: 10px;
      }
    }

    .vehicle-meta {
      margin-top: 8px;

      .vehicle-no {
        margin-bottom: 0;

        .view-stats {
          color: $primary-color;
          font-size: $text_size_4;
          font-weight: 500;
          cursor: pointer;

          > svg {
            margin: 0 5px;
          }
        }
      }

      .sub-title {
        display: flex;
        font-size: $text_size_5;
        margin-bottom: 10px;

        .last-uploaded {
          margin-left: auto;
        }
      }
    }

    .image-container {
      display: flex;
      flex-direction: column;
      height: 444px;

      .vehicle-image {
        flex: 1;
        position: relative;
        background: rgba(0, 0, 0, 0.8);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: var(--vehicle-catalog-image);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .full-screen {
          display: flex;
          align-items: center;
          position: absolute;
          color: white;
          top: 10px;
          right: 10px;
          font-size: $text_size_7;

          .anticon {
            cursor: pointer;
            margin-left: 15px;
            > svg {
              font-size: 16px;
            }
          }
        }

        .navigators {
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;

          .nav-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            background: rgba($text_color_light, 0.6);
            margin-left: 2px;
            color: $white_color;
            font-size: 10px;
            cursor: pointer;
          }
        }
      }

      .vehicle-image-catalog {
        height: 80px;
        display: flex;
        margin-top: 10px;
        overflow: auto;

        .thumbnail {
          min-width: 120px;
          height: 100%;
          margin-right: 5px;
          cursor: pointer;

          &.--selected {
            border: 3px solid $primary-color;
          }
        }
      }
    }
  }

  .__stats-container {
    display: flex;
    flex-direction: column;
    width: 503px;
    border-bottom: 1px solid $secondary_border_color;
    box-shadow: -4px 0px 0px rgb(142 142 142 / 3%);
    padding: 25px 17px;

    .forward-icon {
      display: inline-flex;
      width: 24px;
      height: 24px;
      background: $gray_color;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      opacity: 0.3;
      cursor: pointer;
    }

    .stats-title {
      margin-bottom: 5px;
      margin-top: 15px;
    }

    .vehicle-info {
      @include flex-box(row, flex-start, center);
      @include gap(8px);

      font-size: $text_size_5;

      .value {
        margin-left: 5px;
      }

      .time-container {
        @include flex-box(row, flex-start, center);

        padding: 0;
      }

      svg {
        width: 11px;
        height: 11px;
        color: $light_gray_color;
        margin-right: 3px;
      }
    }

    .location-container {
      display: flex;
      flex-direction: column;

      .location-map {
        height: 144px;
        margin-top: 10px;
      }
    }

    .last-status {
      display: flex;
      flex-direction: column;

      .status-box {
        display: flex;
        height: 44px;
        left: 857px;
        border: 1px solid rgba($secondary_border_color, 0.8);
        border-radius: 4px;

        .status {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 95px;
          border-right: 1px solid rgba($secondary_border_color, 0.8);

          .value-with-color {
            font-size: 13px;
            font-weight: 600;

            &::before {
              top: 6px;
            }
          }
        }

        .time {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0 15px;
        }
      }
    }

    .charts-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .progress-chart {
        margin-top: 20px;
        flex: 1;
        display: flex;
        justify-content: center;
      }

      .charts-info {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .info-container {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 50px;

          .value {
            margin-bottom: 0;
          }

          .unit {
            font-size: $text_size_6;
          }

          .label {
            margin-top: 5px;
            font-size: $text_size_5;
          }
        }
      }
    }
  }

  .__table-container {
    grid-column: 1/3;
    margin: 20px 0 32px 0;

    .vehicle-issue-table {
      display: flex;
      align-items: center;

      .table-title {
        color: $black_color;
        font-weight: bold;
      }

      .search-issue {
        margin-left: auto;
        max-width: 307px;
      }

      .add-issue-btn {
        margin-left: 10px;

        svg {
          margin-right: 5px;
        }
      }
    }

    .common-table {
      &.--custom-height {
        border-bottom: 1px solid $secondary_border_color;
      }
    }
  }
}
