@import "styles/theme.scss";

.ant-input-affix-wrapper {
  &.search-box {
    border: none;
    background-color: rgba($gray_color, 0.18);

    &.filter-search {
      height: 25px;
      border-radius: 3px;

      svg {
        width: 11px;
        height: 11px;
      }
    }

    .ant-input-prefix {
      padding-right: 3px;
    }

    .ant-input {
      background-color: transparent;
    }
  }

  &.--standard {
    width: 307px;
  }
}
