.financial-widget {
  display: flex;
  flex-direction: column;
  height: 100%;
  

  .summary {
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      color: $black_color;
    }

    .chart-container {
      flex: 1;
      display: flex;
      align-items: center;

      .value {
        flex: 2;
        font-size: 36px;
        font-weight: 600;
        color: $black_color;
      }

      .chart {
        flex: 1;
        height: 40px;
      }
    }

    .footer {
      font-size: $text_size_3;
      align-self:self-end;

      .total-value {
        float: right;
        color: $black_color;
        font-weight: 600;
      }
    }
  }

  .detail {
    flex: 1;
    padding-top: 10px;
    align-self:self-end;

    .value {
      flex: 2;
      font-size: 24px;
      font-weight: bold;
      color: $black_color;
    }
  }
}
