@import "styles/theme.scss";

.edit-role {
  width: 422px !important;
}
.form-container {
  .add-new {
    margin-left: 8px;
  }

  .ant-form {
    &.--edit-role-form {
      @include form-modal(0, 225px, 104px);

      .ant-input {
        height: 36px;
      }

      .delete {
        color: $error_color;
      }
    }

    .edit-role-input-wrapper {
      .ant-form-item-control-input-content {
        @include flex-box(row, start, center);

        gap: 19px;
      }

      .delete {
        visibility: hidden;
      }

      &:hover {
        .delete {
          visibility: visible;
        }
      }
    }
  }
}
