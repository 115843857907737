@import "styles/theme.scss";
@import "styles/mixins.scss";

.form-container {
  .ant-form {
    &.--upload-pic-form {
      @include form-modal(0, 225px, 104px);

      .cropped-image-container,
      .croppercontainer {
        @include flex-box(row, center, center);

        position: relative;
        width: 297px;
        height: 269px;
        margin: 0 auto 41px;

        .cropped-image {
          width: 100%;
          height: 100%;
          margin: auto;
        }
      }
      .croppercontainer {
        .image {
          margin: auto;
          // transform: none !important;
        }
      }
      // .ant-upload-drag {
      //   height: 72px;
      //   width: 493px;
      //   margin-bottom: 79px;

      //   .ant-upload-drag-container {
      //     display: flex;
      //     justify-content: space-evenly;
      //     align-items: center;
      //     .ant-upload {
      //       font-size: $text_size_5;
      //       padding: 0;

      //       .ant-upload-btn {
      //         display: flex;
      //         justify-content: space-evenly;
      //         align-items: center;
      //       }
      //       .secondary-color {
      //         color: $secondary_color;
      //       }
      //     }
      //   }
      // }
    }
    // .ant-upload-drag {
    //   height: 72px;
    //   width: 493px;
    //   margin-bottom: 79px;

    //   .ant-upload-drag-container {
    //     display: flex;
    //     justify-content: space-evenly;
    //     align-items: center;
    //     .ant-upload {
    //       font-size: $text_size_5;
    //       padding: 0;

    //       .ant-upload-btn {
    //         display: flex;
    //         justify-content: space-evenly;
    //         align-items: center;
    //       }
    //       .secondary-color {
    //         color: $secondary_color;
    //       }
    //     }
    //   }
    // }
  }
}
