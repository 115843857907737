$text_size_1: 20px;
$text_size_2: 18px;
$text_size_3: 16px;
$text_size_4: 14px;
$text_size_5: 13px;
$text_size_6: 12px;
$text_size_7: 11px;

$primary_color: #871D1A;
$secondary_color: #f9c534;
$separator_color: #ededed;
$white_color: #fff;
$gray_color: #c4c4c4;
$medium_gray_color: #bcbcbc;
$scrollbar_gray_color: #e1e1e1;
$black_color: #000000;
$success_color: #70b857;
$error_color: #ed2020;
$blue_color: #4580d9;
$yellow_color: #ecc600;

$text_color_light: #7c7c7c;
$text_color_secondary: #414141;
$text_color: rgba(#000, 0.6);

$light_gray_color: #aeaeae;
$bg_gray_color: #f4f4f4;
$border_color: #bcbcbc;
$secondary_border_color: #dce6ea;
$upload_bg_color: #fcfcfc;
$link_color: #116eb6;
$link_hover_color: #2e648d;
