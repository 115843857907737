@import "styles/theme.scss";

.custom-select {
  &.error {
    .ant-select-selector {
      border-color: $error_color !important;
    }
  }
  .ant-select-arrow {
    color: $light_gray_color;
  }
}

.ant-menu-vertical {
  .ant-menu-item {
    &.menu-item-hover {
      &:hover {
        background-color: rgba($primary_color, 0.1);
      }
    }
  }
}

.select-search-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}
