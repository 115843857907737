@import "styles/theme.scss";
@import "styles/mixins.scss";

@include filters;

.common-filter {
  .__common-collapse {
    .assigned-vehicle-container {
      .slider-container {
        display: flex;
        position: relative;
        align-items: center;

        .custom-slider {
          flex: 1;

          .ant-slider-track {
            background-color: $primary_color;
          }

          .ant-slider-handle {
            border-color: $primary_color;
            background-color: $primary_color;
          }
        }
      }
    }
  }
}
