@import "styles/theme.scss";
@import "styles/mixins.scss";

.tabs-container {
  margin-left: 7px;

  .grid-container {
    display: grid;
    grid-template-columns: 135px 1fr;

    .title {
      font-weight: 600;
      color: $black_color;
    }

    .issues-container {
      margin-top: 7px;
    }

    .add-new-data {
      display: flex;
      margin-top: 5px;

      @include gap(11px);

      .ant-input,
      .ant-btn {
        height: 34px;
      }
    }

    .boolean > .upload {
      margin-top: 7px;

      .upload-title {
        margin-right: 63px;
      }

      .toggle {
        .ant-switch {
          height: 12px;
          min-width: 36px;

          .ant-switch-handle {
            height: 20px;
            width: 20px;
            top: -4px;
            margin-left: -5px;
          }

          &.ant-switch-checked {
            background-color: $secondary_color;
          }
        }
      }
    }
  }
  .qr-format,
  .qr-character,
  .ant-radio-group {
    display: grid;
  }

  .qr-format {
    margin-bottom: 12px;
  }

  .roles-list {
    display: grid;
    gap: 5px;
    margin-top: 3px;
    color: $text_color_secondary;
  }
}

.add-new,
.edit-role,
.customize {
  color: $secondary_color;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    color: $link_hover_color;
  }
}
.edit-role {
  margin-left: 4px;
}

.customize {
  margin: 0;
}

.theme-wrapper {
  @include grid(354px auto 329px, auto, start, center, 50px);

  margin-top: 11px;

  .colors {
    max-width: 342px;
  }

  .primary-colors-title {
    color: $black_color;
    font-size: $text_size_5;;
  }

  .colors-container {
    display: flex;
    gap: 8px;
    margin-top: 7px;
    flex-wrap: wrap;

    &:first-of-type {
      margin-bottom: 13.17px;
    }
  }
}
