@import "styles/theme.scss";
@import "styles/mixins.scss";

.login-page {
  .login-form {
    .react-code-input,
    &.codes-input-container {
      display: flex !important;
      gap: 14px;
      height: 57px;
      justify-content: space-between;

      input {
        width: 59px;
        height: 57px;
        border: 1px solid $medium_gray_color;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 24px;
        line-height: 24px;
        text-align: center;

        &:focus {
          outline: none;
          border: 1px solid $primary_color;
          border-radius: 4px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
