@import "styles/theme.scss";
@import "styles/mixins.scss";

.add-edit-issues {
  @include custom-modal-style(568px, 422px, 100px);

  .form-container {
    .description,
    .ant-select-selection-item,
    .ant-picker-input > input {
      font-size: $text_size_5;
    }

    .ant-form {
      &.--issues-modal-form {
        @include form-modal(0, 225px, 104px);

        .issue-type {
          display: inline-flex;
          align-items: baseline;
        }
      }
    }
  }
}
