@import "styles/theme.scss";

.action-button {
  display: flex;
  place-items: center;
  gap: 6px;
  height: 34px;
  width: max-content;
  padding: 5px 11px;
  border-radius: 4px;
  margin: 0 13px 0 auto;
  cursor: pointer;
  font-size: $text_size_4;

  &.--success {
    color: #2c9f3f;
    border: 1px solid #2c9f3f;
  }

  &.--danger {
    color: $error_color;
    border: 1px solid $error_color;
  }

  &.--info {
    color: $text_color_secondary;
    border: 1px solid #e3e3e3;
  }
}

.table-action-container {
  @include flex-box(row, flex-end, center);
  @include gap(13px);

  .action-button {
    margin: 0;
  }

  .icon {
    color: $light_gray_color;
    display: block;
    margin: 0;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 7px;
    }

    &.delete-icon {
      &:hover {
        color: $error_color;
      }
    }

    &.edit-icon {
      &:hover {
        color: $link_hover_color;
      }
    }
  }
}
