@import "styles/theme.scss";
@import "styles/mixins.scss";

.label-container {
  height: 30px;

  .label {
    font-size: $text_size_6;
    font-weight: 400;

    > * {
      margin: 0;
    }

    .label-title {
      color: $text_color;
    }
    .label-value {
      color: $black_color;
    }
  }
}

.chart-card {
  .pie-chart-container {
    height: 100%;

    .pie-chart-title {
      display: block;
      text-align: left;
      color: $black_color;
    }
    .empty-state-widget {
      display: grid;
      place-items: center;
      height: 100%;
    }
  }
}
