@import "styles/theme.scss";
@import "styles/mixins.scss";

.add-vehicle {
  @include custom-modal-style(528px, 502px, 106px);

  .action-btn-container {
    margin-top: 34px;
  }
}

.form-container {
  .ant-form {
    &.--add-vehicles-form {
      @include form-modal(0, 225px, 104px);
    }
    &.--add-task-form {
      @include form-modal(0, 375px, 104px);
    }
    &.--edit-shift-form {
      @include form-modal(0, 225px, 104px);
      margin-top: 24px;
    }

    &.--edit-user-form {
      @include form-modal(0, 225px, 104px);
    }
  }

  .action-btn-container {
    display: flex;
    justify-content: flex-end;

    @include gap(8px);
  }
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  display: none;
}

.ant-dropdown {
  .ant-menu {
    height: 254px;
    overflow-y: auto;
    background-color: $white_color;

    &.ant-menu-vertical {
      box-shadow: 0px 2px 6px rgba($black_color, 0.25);
    }

    .ant-menu-item {
      background-color: $white_color;

      &:first-child {
        position: fixed;
        width: 218px;
        padding: 0 5px;
        z-index: 1;
      }
      &:nth-child(2) {
        margin-top: 50px;
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $white_color;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: none;
    border-right-width: 1px;
    outline: 0;
    box-shadow: none;
  }
}
