.body-content {
  // margin-top: 11px;
  height: calc(100vh - 100px);
}

.ant-layout {
  &.full-height {
    height: 100vh;
  }
}
