.enquiries-container {
  .empty-enquiry-list {
    @include flex-box(column, center, center);

    height: calc(100vh - 100px);
    color: $text_color_light;

    .icon {
      color: $light_gray_color;
    }
  }

  .enquiries {
    margin: 20px 13px;

    .heading {
      color: $black_color;
      font-size: $text_size_3;;
      font-weight: 600;
      padding-bottom: 16px;
    }

    .enquiry {
      .enquiry-date {
        font-size: $text_size_6;
        color: $text_color_light;
        padding-top: 5px;
      }

      .enquiry-text {
        @include flex-box(row, flex-start, flex-start);
        @include gap(7px);

        font-size: $text_size_5;
        color: $text_color_secondary;
        padding-bottom: 9px;

        .icon {
          color: #2c9f3f;
        }
      }
    }
  }
}
