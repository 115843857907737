.ant-tag {
  &.common-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    border: none;
    margin: 0;
    border-radius: 3px;
    text-transform: capitalize;

    &.--green {
      color: $success_color;
      background: rgba($success_color, 0.13);
    }

    &.--red {
      color: $error_color;
      background: rgba($error_color, 0.13);
    }

    &.--gray {
      color: rgba($black_color, 0.6);
      background: $bg_gray_color;
    }

    &.--purple {
      color: #7230c8;
      background: rgba(#7230c8, 0.13);
    }

    &.--orange {
      color: $secondary_color;
      background: rgba($secondary_color, 0.13);
    }

    &.--yellow {
      color: #c2a300;
      background: #fff8d3;
    }

    &.--blue {
      color: $blue_color;
      background: rgba($blue_color, 0.13);
    }

    &.--filter-tag {
      float: right;
      border-radius: 2px;
    }

    &.--fixed-width {
      min-width: 73px;
    }

    &.--fixed-dashboard-tag {
      min-width: 102px;
      height: 20px;
    }

    &.vehicle-id-tag {
      margin-right: 5px;
      margin-bottom: 8px ;
      text-transform: initial;
      background: rgba($text_color_secondary, 0.08);
      text-align: center; /* Center text inside the tag */
      display: inline-block; /* Ensure it respects the width */
    }

    &.vehicle-id-tag-list {
      background: rgba($text_color_secondary, 0.06);
    }
  }
}
