@import "styles/theme.scss";

@mixin filters {
  .common-filter {
    .__filter-header {
      padding: 12px 16px 0 16px;

      .all-options {
        @include flex-box(row, flex-start, center);
      }

      .clear-all-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        padding: 4px 0;

        > span:last-child {
          margin-left: 4.8px;
        }
      }

      .ant-radio-group-solid {
        padding-bottom: 2px;
        padding-top: 6px;

        .ant-radio-button-wrapper {
          height: 32px;
          width: 80.5px;
          padding-left: 18px;
        }
      }
    }

    .ant-collapse {
      &.__common-collapse {
        &.--user {
          padding: 0 0 12px;

          .ant-collapse-header {
            padding: 10px 16px 12px;
          }
        }

        .ant-collapse-item {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            width: calc(100% - 32px);
            left: 16px;
            bottom: 0px;
            border-bottom: 1px solid $separator_color;
          }

          .ant-collapse-header {
            font-family: Arial, Helvetica, sans-serif !important;
            color: $black_color;

            &[aria-expanded="true"] {
              padding-bottom: 0;
            }

            svg {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}

@mixin header {
  .common-header {
    display: flex;

    .bulk-actions {
      @include flex-box(row, flex-start, center);
      @include gap(10px);

      margin-left: 10px;

      .ant-btn {
        @include flex-box(row, center, center);
        @include gap(4px);
      }
    }

    .actions-container {
      display: flex;
      margin-left: auto;
      margin-right: 18px;
      align-items: center;

      .ant-radio-group-solid {
        .ant-radio-button-wrapper {
          height: 34px;
        }
      }
      .export-btn {
        margin-right: 10px;
      }

      .custom-icon {
        margin-right: 6px;
      }

      .vertical-separator {
        height: 16px;
      }
    }
  }
}

@mixin switch-button-background($background-color) {
  .ant-radio-button-wrapper-checked {
    background: $background-color;
    background-color: $background-color;
    border-color: $background-color;

    svg {
      color: $text_color_secondary;
    }

    @if ($background-color == $secondary_color) {
      color: $text_color_secondary;
    } @else {
      color: $white_color;
    }

    &::before {
      background-color: $background-color;
    }

    &:first-child {
      border-right-color: $background-color;
    }

    &:hover {
      background: lighten($background-color, 10%);
      background-color: lighten($background-color, 10%);
      border-color: lighten($background-color, 10%);
    }
  }

  .ant-radio-button-wrapper {
    text-transform: capitalize;

    &:not(.ant-radio-button-wrapper-checked) {
      svg {
        color: $gray_color;
      }

      &:hover {
        color: $background-color;
      }
    }
  }
}

@mixin flex-box($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin grid($columns, $rows, $justify, $align, $gap) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin form-modal($margin-left, $input-width, $label-min-width) {
  margin: 0 0 0 $margin-left;
  margin-top: 24px;

  .ant-form-item-label {
    > label {
      font-size: $text_size_5;
      min-width: $label-min-width;
      justify-content: flex-end;
      margin-right: 10px;
      color: $text-color-light;

      &::after {
        content: "";
      }

      &.ant-form-item-required {
        position: relative;
        &::before {
          position: absolute;
          right: -3px;
        }
      }
    }
  }
  .ant-select,
  .ant-select-selector {
    min-height: 36px;
    max-width: $input-width;
    min-width: 225px;
  }
}

@mixin tile-chart($color) {
  > rect {
    fill: $color !important;
  }
}

@mixin login-page() {
  .login-page {
    position: relative;
    height: 100vh;
    display: grid;
    place-items: center end;
    padding-right: 86px;
    background: url("../images/zyp-products.png");
    background-repeat: no-repeat;
    //background-size: 920px;
    background-position: left;
    background-color: rgb(23, 22, 22);  // linear-gradient(to bottom, rgba(119, 8, 8, 0.62), rgba(0, 0, 0, 0.58)),url("../images/zyp-products.png");

    .login-form {
      position: relative;
      height: 377px;
      width: 533px;
      padding: 61px 54px;
      text-align: center;
      background: $white_color;
      box-shadow: 0px 0px 12px rgba($black_color, 0.17);
      border-radius: 6px;
      z-index: 0;

      .form-header {
        margin-bottom: 47px;

        .header-title {
          font-size: 26px;
          font-weight: 700;
          margin-bottom: 0;
          color: $primary_color;
        }

        .header-desc {
          font-weight: 400;
          font-size: $text_size_4;
          line-height: 24px;
          color: $text_color_secondary;
        }
      }

      .form-input {
        .input-desc {
          font-weight: 400;
          font-size: $text_size_5;
          line-height: 24px;
          color: $text_color_light;
          padding-bottom: 16px;
        }
      }

      .form-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 23px;

        @include gap(8px);

        .next-btn,
        .code-btn,
        .verify-btn {
          height: 37px;
        }

        .next-btn,
        .verify-btn {
          width: 80px;
        }

        .code-btn {
          width: 127px;
        }
      }
    }
  }
}

@mixin max-width-ellipsis($max-width) {
  width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin welcome-layout($bg-image) {
  background: url($bg-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

@mixin custom-modal-style($height, $width, $top) {
  height: $height !important;
  width: $width !important;
  top: $top !important;
}

@mixin gap($gap) {
  > *:not(:last-child) {
    margin-right: $gap;
  }
}
