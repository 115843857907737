@import "styles/theme.scss";

.common-table {
  .ant-table-cell {
    .icon-container {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid $separator_color;
      margin-bottom: 0;
    }

    .list-detail {
      display: grid;
      place-items: center flex-start;
      margin-bottom: 0;
      gap: 8px;
      padding-bottom: 5px;

      .list-container {
        @include flex-box(row, center, center);
        @include gap(3px);

        margin-top: 10px;
        gap: 30px;

        .list-item {
          @include flex-box(row, center, center);
          @include gap(8px);

          gap: 8px;
        }
      }
      .icon {
        color: $light_gray_color;

        &.--calendar,
        &.--time,
        &.--phone,
        &.--mail,
        &.--bike {
          margin: 0 6px 0 8px;
        }
      }

      .list-id {
        padding: 5px 0;

        strong {
          &:first-child {
            position: relative;
            margin-right: 16px;

            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 8px;
              border-radius: 50%;
              width: 3px;
              height: 3px;
              left: calc(100% + 8px);
              background: $gray_color;
            }
          }
        }
      }

      svg {
        margin: 0 3px -3px 0;
      }

      .issue-type-tag {
        margin-left: 13px;
        margin-right: 8px;
      }
    }
    .separator {
      height: 12px;
      border-right: 1px solid #c4c4c4;
      margin: 0 12px 0 14px;
    }
  }
}
