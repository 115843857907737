@import "styles/theme.scss";

.column-chart-label {
  font-size: $text_size_6;
  line-height: 24px;

  .label-name {
    color: rgba($black_color, 0.6);
  }

  .label-value {
    color: $black_color;
    margin-left: 1px;
  }
}
