@import "styles/theme.scss";
@import "styles/mixins.scss";



.billing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: column;
  background: $white-color;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(165, 165, 165, 0.25);
  padding: 16px 15px ;
}

.billing-section {
  flex: 1 1 45%;
  margin: 12px 0;
  
}

.billing-row {
  display: flex;
  justify-content: space-between;
}

.billing-tile {
  background-color: #f4efef; /* light gray */
  padding: 30px;
  text-align: center;
  flex: 1;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 4px;
  height: 150px;
  width: 200px;
}

.billing-tile:last-child {
  margin-right:10px;
}

.billing-tile p {
  margin-bottom: 10px;
  font-size: $text_size_3;
  line-height: 24px;
  
}

.billing-tile h1 {
  font-size: 1.0;
  line-height: 44px;
  font-weight: bold;
  margin: 10;
}

.billing-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 3px;
  padding-left: 10px;
}

.total-bill-tile {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}


.tile-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  background: $white-color;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(165, 165, 165, 0.25);
  padding: 16px 15px;
  min-width: 221px;
  height: 130px;

  .value-chart {
    @include flex-box(row, space-between, center);

    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure that the value is centered horizontally */
    justify-content: center; /* Ensure that the value is centered vertically */
    height: 100%;
    width: 100%;
    color: $black_color;
    margin-bottom: 8px;

    .tile-value {
      font-size: 26px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 0;
      text-align: center;
    }

    .tile-figure {
      display: flex;
      place-items: center;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 115px;

      > div {
        width: 100%;
      }

      .emptyTileChart {
        width: 27px;
        border-bottom: 1px dashed red;
        margin-top: 17px;
        margin-left: auto;
      }
    }
  }

  .name-link {
    display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;/* Centers content within the grid cell */
  width: 100%;
  height: 100%; /* Ensure that the container takes full height */

    .tile-name {
      font-size: $text_size_4;
      line-height: 24px;
      text-align: center; /* Centers the text within the element */
      margin: 0;/* Makes the element full width so that text-align:center works */
      justify-self: center;
    }

    .tile-link {
      color: $link_color;
      text-align: center;
    }
  }

  &.vehicle-tile {
    @include flex-box(row, space-between, unset);

    .tile-left-side {
      display: grid;
      justify-content: flex-start;
      align-self: left;

      .tile-value {
        font-size: 26px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 10px;
        color: $black-color;

        .amount-unit {
          font-size: $text_size_3;
          margin-left: 6px;
        }
      }

      .tile-name {
        font-size: $text_size_3;
        line-height: 24px;
        color: $text_color_secondary;

        .payments-per {
          color: $gray_color;
          font-size: $text_size_4;
        }

        .tile-info {
          font-size: $text_size_5;

          .on-hire-count {
            color: #2c9f3f;
            margin: 0 3.5px 0 4px;
          }
        }
      }

      .tile-link {
        color: $link_color;
      }
    }

    .tile-right-side {
      align-self: center;

      .permit-expiring-data {
        position: relative;
        display: flex;
        place-items: center;

        &::before {
          position: absolute;
          content: "";
          left: -15%;
          width: 1px;
          height: 61px;
          border-left: 1px dashed $secondary_border_color;
        }

        .data-box {
          @include flex-box(column, center, center);

          height: 82px;
          width: 104px;
          background: #f4f4f4;
          border-radius: 4px;

          .count {
            font-size: 20px;
            color: $black_color;
          }

          .title {
            font-size: $text_size_5;
            color: $text_color_secondary;
          }

          &:not(:last-child) {
            margin-right: 2px;
          }
        }
      }
    }
  }
}
