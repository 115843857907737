.vehicles-header {
  display: flex;

  .actions-container {
    display: flex;
    margin-left: auto;
    margin-right: 18px;
    align-items: center;

    .export-btn {
      margin-right: 10px;
    }

    .custom-icon {
      margin-right: 6px;
    }

    .vertical-separator {
      height: 16px;
    }
  }
}
.ant-dropdown-trigger {
  &.vehicle-menu {
    @include flex-box(row, center, center);

    width: 35px;
    height: 34px;
    border: 1px solid $border_color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    color: $text_color_secondary;

    &.ant-dropdown-open {
      background: $bg_gray_color;
    }
  }
}

.vehicle-drop-menu {
  width: 142px;
}
