@import "styles/theme.scss";

.actions-container {
  .filter-switch-label {
    margin-right: 8px;
  }

  &.--fixed-width {
    .ant-radio-button-wrapper {
      width: 93px;
      height: 34px;
      padding: 0 8px;
      text-align: center;
    }

    .filter-switch-label {
      display: none;
    }
  }

  .ant-radio-button-wrapper {
    .switch-label-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 34px;
      gap: 7px;

      svg {
        color: #c4c4c4;
      }
    }
  }
}

.ant-radio-group {
  &.ant-radio-group-solid {
    display: inline-flex;
    align-items: center;
  }

  &.filter-switch {
    &.--primary {
      @include switch-button-background($primary_color);
    }

    &.--secondary {
      @include switch-button-background($secondary_color);
    }
  }
}
