@import "styles/theme.scss";
@import "styles/mixins.scss";

.user-detail-header {
  @include flex-box(row, space-between, center);

  padding: 16px 0 13px 16px;

  .ant-breadcrumb-link {
    .breadcrumb-active {
      color: $secondary_color;
      padding-right: 0;

      &:hover {
        color: $secondary_color;
      }
    }
  }

  .header-actions {
    @include flex-box(row, center, center);

    gap: 9px;

    .ant-btn {
      @include flex-box(row, space-between, center);

      gap: 6px;
    }
  }
}

.user-detail-content {
  @include grid(12px 219.6px 1fr 1fr, auto, space-between, flex-start, 8.6px);

  padding-bottom: 30px;

  .profile {
    display: grid;

    .--pic-container {
      position: relative;
      height: 144px;
      width: 144px;
      border-radius: 50%;
      margin: 0 auto 12px;
      cursor: pointer;

      img {
        width: 100%;
        border-radius: 50%;
      }

      .--update-pic {
        position: absolute;
        bottom: 8%;
        right: 0;
        background-color: rgba($text_color_light, 0.7);
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: inline-grid;
        place-items: center;
        color: $white_color;
      }
    }
  }

  .user {
    display: grid;
    margin-left: 16.4px;

    & > * {
      line-height: 32px;
      color: $text_color_secondary;
    }

    .--name {
      font-weight: 500;
      font-size: $text_size_3;
      margin-bottom: 10px;
    }

    .--contact-info {
      font-weight: 400;
      font-size: $text_size_5;
      display: flex;
      gap: 7px;
      line-height: $text_size_5;
      margin-bottom: 12px;

      .--icon {
        color: $light_gray_color;
      }
    }

    .performance {
      @include grid(max-content auto, auto, baseline, center, 6px);

      height: 60px;
      margin-top: 4px;
      border-top: 1px solid rgba($border_color, 0.5);
      border-bottom: 1px solid rgba($border_color, 0.5);

      .rating {
        font-size: 30px;
        color: inherit;
      }

      .rating-info {
        display: grid;
        place-items: baseline, center;

        .stars {
          height: 20px;

          .ant-rate-star {
            margin-right: 2px;
          }

          svg {
            width: 9.93px;
            height: 9.48px;
          }
        }

        .caption {
          font-size: $text_size_5;
          color: inherit;
          margin-bottom: 5px;
        }
      }
    }
  }

  .profile-info {
    margin-left: 35px;
    min-width: 700px;
    text-align: start; 
    display: inline-block; 
    .--title {
      font-weight: 600;
      font-size: $text_size_4;
      line-height: 24px;
      color: $black_color;
      padding: 0 8px;
      margin: 0 0 3px;
    }

    .common-table {
      width: 80%;
      text-align: start; 

      .ant-table {
        height: auto;
      }

      .info-row {
        &.--user-details {
          .ant-table-cell {
            background: $white_color;
            text-align: start; 
          }
        }
        &:hover {
          box-shadow: none;

          .ant-table-cell {
            background: $white_color;
            
          }
        }

        &:nth-child(odd) {
          background: $white_color;
        }

        .ant-table-cell {
          border: none;
          font-size: $text_size_5;
          padding: 8.5px 8px;

          &:first-child {
            color: $text_color_light;
            width: 169px;
          }

          .ant-tag {
            &.user-tag {
              color: $text_color_secondary;
            }
          }
        }
      }
    }

    .general-stats,
    .task-breakdown {
      margin-top: 13.5px;

      .--title {
        margin: 20px 0 8px;
      }

      .tiles-container {
        @include grid(1fr 1fr 1fr, auto, space-between, center, 15px);
      }
    }

    .task-breakdown {
      margin-top: 23px;
    }
  }
  .updated-at {
    text-align: right;
  }
}
