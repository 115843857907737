@import "styles/theme.scss";
@import "styles/mixins.scss";

.dashboard-content {
  display: flex;
  // flex-direction: column;
  width: 100%;
  min-width: 1193px;
  height: auto;
  padding: 16px;
  gap: 18px;

  > * {
    font-family: Roboto, sans-serif;
  }

  .tiles-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 18px;
    grid-column: 1/3;

    .ant-col {
      &.permit-expiry {
        grid-column: 2/4;
      }
    }
  }
}
.dashboard-charts-container {
  width: 100%;
  min-width: 1193px;
  padding: 4px 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 17px 13px;
}

.select-dashboard-view {
  width: 142px !important;

  padding: 8.8px 0 11px 0 !important;
}

.widget-item {
  &.--issue-tiles,
  &.--issue-count,
  &.--vehicle-tiles,
  &.--financials-tiles {
    grid-column: 1/3;
  }

  &.--un-allocated-vehicles {
    grid-row: 1 / 3;
    grid-column: 3 / 4;
    height: 100% !important;
  }

  &.custom-card-wrapper {
    height: 280px;
    box-shadow: 0px 3px 6px rgba(165, 165, 165, 0.25);
    border-radius: 4px;
    min-width: 378px;
    background: $white_color;

    .chart-card {
      padding: 13px 18px;
      height: 100%;

      .dashboard-table-header {
        @include flex-box(row, space-between, center);

        padding: 0 3px 3px 0;

        & > * {
          line-height: 24px;
        }
      }

      .vehicle-dashboard-tabs {
        .ant-tabs-nav-wrap {
          margin-top: 5px;
          border-top: 1px solid $separator_color;
          background-color: rgba($bg_gray_color, 0.4);
        }
      }
    }

    .dashboard-table-title {
      color: $black-color;

      &.--vehicle {
        @include flex-box(row, center, center);

        @include gap(8px);

        .separator {
          width: 1px;
          height: 11px;
          background-color: $secondary_border_color;
        }
      }
    }
  }

  .dashboard-table-link {
    @include flex-box(row, unset, end);

    color: $link_color;

    @include gap(4px);
  }

  .open-view-link-container {
    @include gap(6px);
  }

  .open-view-link-container,
  .dashboard-table-link {
    cursor: pointer;
  }

  .k2-pie--center-container {
    .k2-pie--center-value {
      font-family: "Roboto-Medium", sans-serif;
      color: $black_color;
      font-weight: 500;
    }

    .k2-pie--center-label {
      line-height: 24px;
      font-size: $text_size_6;

      &::after {
        content: "Tasks";
        margin-left: 4px;
      }
    }
  }

  .--dashboard-table {
    .moved-mid {
      padding-left: 45px;
    }

    .moved-right {
      padding-left: 60px;
    }

    .moved-left {
      padding-left: 0;
    }
  }
}

.k2--legend-item {
  .k2--legend-symbol {
    width: 7px;
    height: 7px;
  }

  .k2--legend-value {
    font-size: $text_size_6;
    margin-left: 5px;
  }
}

.ant-tabs-nav-list {
  .ant-tabs-tab {
    .custom-tab-pane {
      .count {
        background: #efecec;
        color: rgba($black_color, 0.6);
        border-radius: 2px;
        margin-left: 4px;
        padding: 1px 3px;
      }
    }

    &.ant-tabs-tab-active {
      .custom-tab-pane {
        .count {
          background: #fcf2c0;
          color: #ecb723;
        }
      }
    }
  }
}
